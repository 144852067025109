import React, { Fragment, useState, useEffect, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import { Cancel, FiberManualRecord, BorderColor } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'
import axios from 'axios'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Moment from 'moment'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function ViewTest(props) {
  const classes = useStyles()

  const [errorServer, setErrorServer] = useState(false)
  const [loader, setLoader] = useState(true)
  const [rows, setRows] = useState([])
  const [returnLogin, setReturnLogin] = React.useState(false)

  let dataTest = props.id
  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    axios
      .get(`${urlServices}tests/${dataTest.id}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRows(response.data)
          setLoader(false)
        } else {
          setRows([])
          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          console.log(e)

          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)

          setRows([])
          setErrorServer(true)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  let n = 1

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>
                  <FiberManualRecord className={classes.iconTheme} />{' '}
                  {rows.name}
                </div>
                <br></br>
              </Grid>
              {errorServer ? (
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">
                    Error en la consulta con sel servidor.
                  </Alert>
                </Grid>
              ) : (
                ''
              )}
              {loader ? (
                <Grid item xs={12} sm={12}>
                  <Box sx={{ width: '100%' }}>
                    <Skeleton width="100%" height={200} />
                  </Box>
                </Grid>
              ) : (
                <Fragment>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.titleFilterAction}>
                      Cargo: &nbsp;&nbsp;
                      {rows.testjob.length > 0 ? (
                        rows.testjob[0].name
                      ) : (
                        <span style={{ color: 'red' }}>
                          NO HAY CARGO SELECCIONADO
                        </span>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div className={classes.titleFilterAction}>
                      Puntaje total: &nbsp;&nbsp;{rows.totalScore}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div className={classes.titleFilterAction}>
                      Tiempo:&nbsp;&nbsp;{rows.time} min.
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div className={classes.titleFilterAction}>
                      Nº preguntas:&nbsp;&nbsp;
                      {rows.totalQuestions}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div className={classes.titleFilterAction}>
                      Fecha de activación:&nbsp;&nbsp;
                      {rows.activationTime ? 'SI' : 'NO'}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div className={classes.titleFilterAction}>
                      Desde:&nbsp;&nbsp;
                      {rows.activationTime
                        ? Moment(rows.activationStart).format(
                            'DD-MM-YYYY HH:mm:ss'
                          )
                        : 'SIN FECHA'}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div className={classes.titleFilterAction}>
                      Hasta:&nbsp;&nbsp;
                      {rows.activationTime
                        ? Moment(rows.activationEnd).format(
                            'DD-MM-YYYY HH:mm:ss'
                          )
                        : 'SIN FECHA'}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div className={classes.titleFilterAction}>
                      Gestionar contraseñas:&nbsp;&nbsp;
                      {rows.managePasswords ? 'SI' : 'NO'}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div className={classes.titleFilterAction}>
                      Preguntas aleatorias:&nbsp;&nbsp;
                      {rows.isRandom ? 'SI' : 'NO'}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <div className={classes.cardTitle}>
                      <center>Preguntas y respuestas</center>
                    </div>
                  </Grid>
                  {rows.question.length > 0 ? (
                    rows.question.map((row, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={{ paddingLeft: 20, paddingBottom: 10 }}
                        >
                          <Alert icon={false} severity="info">
                            <div className={classes.fontParagraph}>
                              <b>
                                {n++}
                                {' .- ' + row.statement}
                              </b>{' '}
                              <small>({row.questiontype.name})</small>
                            </div>
                            {row.answer.map((row2x) => {
                              return (
                                row2x.status === 1 && (
                                  <div
                                    style={{ marginLeft: 35 }}
                                    key={row2x.id}
                                  >
                                    {' '}
                                    {/* Asegúrate de agregar una key única */}
                                    &nbsp; &nbsp;&nbsp;{row2x.statement}
                                    <span
                                      style={{
                                        color: green[800],
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {row2x.rightAnswer === true ? (
                                        ' (Verdadero)'
                                      ) : (
                                        <span style={{ color: 'red' }}>
                                          &nbsp;(Falso)
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                )
                              )
                            })}
                          </Alert>
                        </Grid>
                      )
                    })
                  ) : (
                    <Grid item xs={12} sm={12}>
                      <b style={{ color: '#C5C5C5', padding: 5 }}>
                        <center> No preguntas para esta prueba </center>
                      </b>
                    </Grid>
                  )}
                </Fragment>
              )}
            </Grid>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
